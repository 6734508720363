import { createContext, useEffect, useState } from "react";

import { useConfig } from "./config";

import { PopupData } from "../types/configuration";

type PopupContextProps = {
  popupData: PopupData | null;
  setPopupData: (value: any) => void;
};

const PopupContext = createContext<PopupContextProps>({
  popupData: null,
  setPopupData: () => null,
});

export const PopupProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { popups } = useConfig(); // uzycie konfiguracji z contextu
  const [popupData, setPopupData] = useState<PopupData | null>(null);

  // obsluga popupow po zaladowaniu oraz przy nieaktywnosci
  useEffect(() => {
    if (!popups || !popups.length) return;

    const handlePopupTrigger = (popup: PopupData) => {
      if (popup.trigger.type === "onPageLoad") {
        setTimeout(() => {
          setPopupData({ ...popup, visible: true });
        }, popup.trigger.delay);
      } else if (popup.trigger.type === "onInactivity") {
        let timeoutId: ReturnType<typeof setTimeout>;

        const resetTimeout = () => {
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
            setPopupData({ ...popup, visible: true });
          }, popup.trigger.delay);
        };

        window.addEventListener("mousemove", resetTimeout);
        window.addEventListener("keydown", resetTimeout);

        resetTimeout();

        return () => {
          clearTimeout(timeoutId);
          window.removeEventListener("mousemove", resetTimeout);
          window.removeEventListener("keydown", resetTimeout);
        };
      }
    };

    popups.forEach(handlePopupTrigger);
  }, [popups]);

  const handlePageVisit = () => {
    const currentUrl = window.location.pathname;
    popups.forEach((popup) => {
      if (
        popup.trigger.type === "onPageVisit" &&
        popup.trigger.urls?.includes(currentUrl)
      ) {
        setPopupData({ ...popup, visible: true });
      }
    });
  };

  // obsluga popupow przy zmianie sciezki - wyswietlanych na poszczegolnych podstronach
  useEffect(() => {
    if (!popups || !popups.length) return;

    handlePageVisit();
  }, [location.pathname]);

  // for SPA sites like Gatsby we need to add custom event onRouteUpdate in gatsby-browser (gatsby-route-change)
  useEffect(() => {
    if (!popups || !popups.length) return;

    handlePageVisit();

    const onRouteChange = () => {
      handlePageVisit();
    };

    window.addEventListener("gatsby-route-change", onRouteChange);

    return () => {
      window.removeEventListener("gatsby-route-change", onRouteChange);
    };
  }, [popups]);

  // obsluga customowych eventow
  useEffect(() => {
    const handleCustomEvent = (event) => {
      const customPopup = popups.find(
        (popup) =>
          popup.trigger.type === "onCustomEvent" &&
          popup.trigger.eventName === event.type
      );
      if (customPopup) {
        setPopupData({ ...customPopup, visible: true });
      }
    };

    popups.forEach((popup) => {
      if (popup.trigger.type === "onCustomEvent") {
        window.addEventListener(popup.trigger.eventName, handleCustomEvent);
      }
    });

    return () => {
      popups.forEach((popup) => {
        if (popup.trigger.type === "onCustomEvent") {
          window.removeEventListener(
            popup.trigger.eventName,
            handleCustomEvent
          );
        }
      });
    };
  }, [popups]);

  return (
    <PopupContext.Provider
      value={{
        popupData,
        setPopupData,
      }}
    >
      {children}
    </PopupContext.Provider>
  );
};

export default PopupContext;
