import { useState } from "react";
import styled, { css } from "styled-components";
import { FieldProps } from "formik";

import checkIcon from "../assets/check-white.svg";

const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 0 8px;
  font-size: 11px;
  line-height: 1.3;
  padding: 3px 0 0 30px;
  cursor: pointer;
`;

const LabelText = styled.p<{ expanded: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-break: break-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: ${({ theme }) => theme.colors.black};
  margin: 0;
  ${({ expanded }) =>
    expanded &&
    css`
      display: block;
      text-overflow: clip;
      -webkit-line-clamp: none;
      word-break: normal;
    `}
`;

const LabelBtn = styled.button`
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  align-self: flex-start;
  padding: 0 0 0 10px;
  margin-left: auto;
  font-family: ${({ theme }) => theme.fonts.primary};
`;

const Checkmark = styled.span<{ error: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background: transparent;
  border: 1px solid;
  transition: 0.2s;
  border-radius: 4px;
  border-color: ${({ theme, error }) =>
    error ? theme.colors.red : theme.colors.gray400};
  &:after {
    content: "";
    position: absolute;
    opacity: 0;
    transition: 0.2s;
    left: 3px;
    top: 3px;
    width: 12px;
    height: 12px;
    background: url(${checkIcon}) no-repeat center;
  }
`;

const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked ~ ${Checkmark} {
    background: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
    &:after {
      opacity: 1;
    }
  }
`;

type Props = {
  label: string;
};

const FormCheckbox: React.FC<Props & FieldProps> = ({
  field: { name, onChange, onBlur, value },
  form: { touched, errors },
  label,
}) => {
  const [expanded, setExpaned] = useState(false);

  const invalid = !!(touched[name] && errors[name]);
  const uniqueID = `${name}-${(Math.random() + 1).toString(36).substring(7)}`;

  return (
    <Label htmlFor={uniqueID}>
      <LabelText
        expanded={expanded}
        dangerouslySetInnerHTML={{ __html: label }}
      />
      <LabelBtn
        type="button"
        onClick={() => {
          setExpaned(!expanded);
        }}
      >
        {expanded ? "Mniej" : "Więcej"}
      </LabelBtn>
      <Checkbox
        id={uniqueID}
        name={name}
        type="checkbox"
        onChange={onChange}
        onBlur={onBlur}
        checked={value}
      />
      <Checkmark error={invalid} />
    </Label>
  );
};

export default FormCheckbox;
