import type { Breakpoints, Colors, Fonts } from "../types/theme";

export const breakpoints: Breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1430,
  xxxl: 1600,
};

export const colors: Colors = {
  yellow: "#FFC10E",
  lightyellow: "#F8ECCB",
  darkyellow: "#A18F5E",
  darkyellow100: "#E4C68E",
  darkyellow200: "#FCE7AC",
  red: "#F50000",
  green: "#84CB1C",
  light: "#FEF6E2",
  dark: "#212121",
  text: "#4E4E4E",
  lighttext: "#888888",
  white: "#FFFFFF",
  black: "#000000",
  gray: "#F1F1F1",
  gray100: "#C7C7C7",
  gray200: "#E5E5E5",
  gray300: "#7B7B7B",
  gray400: "#CBCBCB",
  darkgray: "#707070",
  available: "#3ba440",
  reserved: "#fcb21f",
  sold: "#e81d25",
  planned: "#FFC10E",
  primary: "#f50000",
  secondary: "#f8eccb",
  tertiary: "ffc10e",
};

export const fonts: Fonts = {
  size: "16px",
  primary: "Lexend, sans-serif",
};

const theme = {
  breakpoints,
  colors,
  fonts,
};

export default theme;
