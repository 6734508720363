import { useContext, useRef } from "react";
import styled, { css } from "styled-components";
import PopupContext from "../contexts/popup";
import useOutsideClick from "../hooks/useOutsideClick";

import closeIcon from "../assets/close.svg";
import media from "../styles/media";

import DefaultForm from "./form";

const Overlay = styled.div<{ $active: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 400;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  transition-property: opacity, visibility;
  opacity: 0;
  visibility: hidden;

  ${({ $active }) =>
    $active &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

const Content = styled.div<{ $image: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 80svh;
  min-height: fit-content;

  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px;

  ${media.lg.min} {
    max-width: 586px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 32px 40px;
  }

  ${({ $image }) =>
    $image &&
    css`
      padding: 0;
      aspect-ratio: 8 / 6;

      ${media.lg.min} {
        padding: 0;
      }
    `}
`;

const InnerWrapper = styled.div`
  overflow-y: auto;
  max-height: calc(80svh - 64px);

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const CloseButton = styled.button`
  position: absolute;
  right: 16px;
  top: 0;
  transform: translateY(-50px);
  background-color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  padding: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 1;

  ${media.lg.min} {
    width: 60px;
    height: 60px;
    right: 0;
    transform: translate(50%, -50%);
  }
`;

const Title = styled.p`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 4px !important;

  ${media.lg.min} {
    margin-bottom: 8px !important;
  }
`;

const Text = styled.p`
  font-size: 14px;
  text-align: center;
`;

const FormWrapper = styled.div`
  padding: 20px 0 0;
  display: flex;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Popup = () => {
  const { popupData, setPopupData } = useContext(PopupContext);

  const popupRef = useRef(null);

  const closePopup = () => {
    setPopupData({ ...popupData, visible: false });
  };

  useOutsideClick({
    ref: popupRef,
    handler: closePopup,
    condition: popupData?.visible,
  });

  return (
    <Overlay $active={popupData?.visible}>
      <Content
        $active={popupData?.visible}
        ref={popupRef}
        $image={popupData?.type === "image"}
      >
        <CloseButton onClick={closePopup}>
          <img src={closeIcon} alt="" />
        </CloseButton>
        {popupData?.type === "form" && (
          <InnerWrapper>
            <Title dangerouslySetInnerHTML={{ __html: popupData?.title }} />
            <Text dangerouslySetInnerHTML={{ __html: popupData?.text }} />
            <FormWrapper>
              <DefaultForm endpoint={popupData.endpoint} />
            </FormWrapper>
          </InnerWrapper>
        )}
        {popupData?.type === "image" && (
          <ImageWrapper
            as={popupData.link ? "a" : "div"}
            href={popupData.link ?? ""}
          >
            <img src={popupData.imageUrl} alt="" />
          </ImageWrapper>
        )}
      </Content>
    </Overlay>
  );
};
