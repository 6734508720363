import styled, { css } from "styled-components";
import { FieldProps } from "formik";

type InputProps = {
  readOnly: boolean;
  textarea: boolean;
  invalid: boolean;
  valid: boolean;
  as: keyof JSX.IntrinsicElements;
  type: "text";
};

const InputItem = styled.div<{ disabled: boolean }>`
  position: relative;
  width: 100%;
  overflow: hidden;
  font-size: 15px;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "all")};
`;

const Label = styled.label<{ invalid: boolean }>`
  position: absolute;
  top: 16px;
  left: 16px;
  opacity: 0;
  visibility: hidden;
  color: ${({ theme }) => theme.colors.dark};
  pointer-events: none;
  white-space: nowrap;
  transition: opacity 0.2s, color 0.2s;

  span {
    color: ${({ theme }) => theme.colors.red};
  }
  ${({ invalid }) =>
    invalid &&
    css`
      color: ${({ theme }) => theme.colors.red};
    `}
`;

const StyledInput = styled.input<InputProps>`
  width: 100%;
  padding: 16px;
  height: ${({ textarea }) => (textarea ? "120px" : "55px")};
  background: transparent;
  border: 1px solid
    ${({ theme, valid }) => (valid ? theme.colors.green : theme.colors.gray400)};
  border-radius: 4px;
  transition: 0.3s;
  resize: none;
  outline: 0;
  font-family: ${({ theme }) => theme.fonts.primary};
  &:focus {
    border-color: ${({ theme, valid }) =>
      valid ? theme.colors.green : theme.colors.primary};
  }
  &:placeholder-shown + ${Label} {
    opacity: 1;
    visibility: visible;
  }
  ${({ invalid }) =>
    invalid &&
    css`
      border-color: ${({ theme }) => theme.colors.red} !important;
      transition: none;
      color: transparent;
      & + ${Label} {
        opacity: 1;
        visibility: visible;
      }
      &:focus {
        color: ${({ theme }) => theme.colors.dark};
        transition-duration: 0.2s;
        transition-property: color, opacity, border-color, background;
        & + ${Label} {
          opacity: 0;
          visibility: hidden;
        }
      }
    `}
`;

type Props = {
  label: string;
  textarea?: boolean;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
};

const FormInput: React.FC<Props & FieldProps> = ({
  field: { name, onChange, onBlur, value },
  form: { touched, errors },
  label,
  textarea,
  required,
  disabled,
  readonly,
}) => {
  const labelText = ((errors[name] && touched[name] && errors[name]) ||
    (required ? `${label}<span>*</span>` : label)) as string;

  const invalid = !!(touched[name] && errors[name] && required);
  const valid = !!(touched[name] && !errors[name] && required);

  return (
    <InputItem disabled={!!disabled}>
      <StyledInput
        id={name}
        name={name}
        type="text"
        as={textarea ? "textarea" : "input"}
        textarea={!!textarea}
        placeholder=" "
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        invalid={invalid}
        valid={valid}
        disabled={disabled}
        readOnly={!!readonly}
      />
      <Label
        htmlFor={name}
        invalid={invalid}
        dangerouslySetInnerHTML={{ __html: labelText }}
      />
    </InputItem>
  );
};

export default FormInput;
