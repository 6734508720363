{
  "colors": {
    "primaryColor": "#6B96D6",
    "secondaryColor": "#81D4FA"
  },
  "popups": [
    {
      "id": "popup-1",
      "type": "image",
      "imageUrl": "https://picsum.photos/seed/picsum/1280/720",
      "link": "https://www.google.com",
      "trigger": {
        "type": "onPageLoad",
        "delay": 1000
      }
    },
    {
      "id": "popup-2",
      "type": "form",
      "endpoint": "https://www.facebook.com",
      "title": "onInactivity title",
      "text": "onInactivity text",
      "trigger": {
        "type": "onInactivity",
        "delay": 5000
      }
    },
    {
      "id": "popup-3",
      "type": "image",
      "imageUrl": "https://picsum.photos/seed/picsum/1280/720",
      "trigger": {
        "type": "onPageVisit",
        "urls": ["/kontakt/", "/polityka-prywatnosci/"]
      }
    },
    {
      "id": "popup-4",
      "type": "form",
      "endpoint": "https://www.facebook.com",
      "title": "Widget title",
      "text": "Widget text",
      "widgetText": "Skontaktuj się",
      "widgetImageUrl": "https://img.icons8.com/?size=100&id=QfXoGJ7IiNP0&format=png&color=000000",
      "trigger": {
        "type": "onWidgetClick"
      }
    },
    {
      "id": "popup-5",
      "type": "image",
      "imageUrl": "https://picsum.photos/seed/picsum/1280/720",
      "trigger": {
        "type": "onCustomEvent",
        "eventName": "customEventName"
      }
    }
  ]
}
