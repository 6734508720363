import styled, { css } from "styled-components";
import media from "../styles/media";

import closeIcon from "../assets/close.svg";
import { useContext, useState } from "react";
import PopupContext from "../contexts/popup";

import { PopupData } from "../types/configuration";

const Wrapper = styled.div<{ $minimized: boolean }>`
  position: fixed;
  bottom: 16px;
  right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  z-index: 399;

  ${media.lg.min} {
    bottom: 24px;
    right: 24px;
  }

  ${({ $minimized }) =>
    $minimized &&
    css`
      transform: scale(0.7) translate(30px, 30px);
      transform-origin: bottom right;
    `}
`;

const IconWrapper = styled.div``;

const Banner = styled.div<{ $minimized: boolean }>`
  margin-top: -10px;
  white-space: nowrap;
  background-color: ${({ theme }) => theme.colors.black};
  padding: 4px 8px;
  color: white;
  font-size: 12px;
  font-weight: 700;
  border-radius: 4px;
  text-transform: uppercase;
  opacity: 1;
  visibility: visible;
  transition: 0.3s;
  display: block;

  ${({ $minimized }) =>
    $minimized &&
    css`
      transform: scale(0, 0);
      transform-origin: bottom right;
    `}
`;

const CloseButton = styled.button<{ $minimized: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  padding: 0;
  width: 36px;
  height: 36px;
  cursor: pointer;

  opacity: 1;
  visibility: visible;
  transition: 0.3s;

  ${({ $minimized }) =>
    $minimized &&
    css`
      visibility: hidden;
      opacity: 0;
    `}
`;

type Props = {
  text: string;
  image: string;
  popup: PopupData;
};

export const Widget: React.FC<Props> = ({ text, image, popup }) => {
  const [minimized, setMinimized] = useState(() => {
    const widgetMinimized = sessionStorage.getItem("widgetMinimized");
    return widgetMinimized === "true";
  });

  const { setPopupData } = useContext(PopupContext);

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setMinimized(true);
    sessionStorage.setItem("widgetMinimized", "true");
  };

  return (
    <Wrapper
      $minimized={minimized}
      onClick={() => {
        if (minimized) {
          setMinimized(false);
          sessionStorage.setItem("widgetMinimized", "false");
        } else {
          setPopupData({ ...popup, visible: true });
        }
      }}
    >
      <IconWrapper>
        <img src={image} alt="" />
      </IconWrapper>

      <Banner
        dangerouslySetInnerHTML={{ __html: text }}
        $minimized={minimized}
      />

      <CloseButton onClick={handleClose} $minimized={minimized}>
        <img src={closeIcon} alt="" />
      </CloseButton>
    </Wrapper>
  );
};
