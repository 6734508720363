import { Widget } from "./components/widget";
import { Popup } from "./components/popup";
import { ThemeProvider } from "styled-components";
import theme from "./styles/theme";
import GlobalStyles from "./styles/global";
import { PopupProvider } from "./contexts/popup";
import { ConfigProvider, useConfig } from "./contexts/config";

const ThemedApp = () => {
  const { colors, popups } = useConfig();

  const customTheme = {
    ...theme,
    colors: {
      ...theme.colors,
      primary: colors.primaryColor,
      secondary: colors.secondaryColor,
    },
  };

  const widgetPopup = popups.find(
    (popup) => popup.trigger.type === "onWidgetClick"
  );

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <PopupProvider>
          <GlobalStyles />
          <Widget
            text={widgetPopup.widgetText}
            image={widgetPopup.widgetImageUrl}
            popup={widgetPopup}
          />
          <Popup />
        </PopupProvider>
      </ThemeProvider>
    </>
  );
};

const App = () => {
  return (
    <>
      <ConfigProvider>
        <ThemedApp />
      </ConfigProvider>
    </>
  );
};

export { App };
