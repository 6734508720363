import * as Yup from "yup";

export const PHONE_REGEXP = /^[0-9]+$/;

export const CONTACT_FORM_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .min(3, "Wpisz minimum 3 znaki")
    .required("Proszę o podanie imienia i nazwiska"),
  email: Yup.string()
    .email("Adres email jest nieprawidłowy")
    .required("Proszę podać adres e-mail"),
  phone: Yup.string()
    .min(9, "Numer telefonu jest nieprawidłowy")
    .matches(PHONE_REGEXP, "Numer telefonu jest nieprawidłowy")
    .required("Proszę podać numer telefonu"),
  message: Yup.string().required("Proszę podać wiadomość"),
  agree1: Yup.bool().oneOf([true], "Proszę zaznaczyć zgodę"),
  agree2: Yup.bool().oneOf([true], "Proszę zaznaczyć zgodę"),
  agree3: Yup.bool().oneOf([true], "Proszę zaznaczyć zgodę"),
});

export const CONTACT_FORM_INIT_VALUES = {
  name: "",
  email: "",
  phone: "",
  message: "",
  agree1: false,
  agree2: false,
  agree3: false,
};
