import { createContext, useContext, useState, useEffect } from "react";

// @ts-ignore
import configuration from "../assets/configuration.json";

import { Config } from "../types/configuration";

const ConfigContext = createContext<Config | null>(null);

export const useConfig = () => {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }
  return context;
};

export const ConfigProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [config, setConfig] = useState<Config | null>(null);

  //   pobranie konfiguracji z API (tymczasowo z pliku)
  useEffect(() => {
    // fetch("/path/to/config.json")
    //   .then((response) => response.json())
    //   .then((data) => setConfig(data));
    setConfig(configuration);
  }, []);

  if (!config) {
    return <div>Loading configuration...</div>;
  }

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};
