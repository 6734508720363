import { useRef, useState } from "react";
import styled from "styled-components";
import { Formik, Field, Form, FormikHelpers } from "formik";
import Input from "./input";
import Checkbox from "./checkbox";
import Button from "./button";

import {
  CONTACT_FORM_SCHEMA,
  CONTACT_FORM_INIT_VALUES,
} from "../constants/form";

import { ContactFormValues } from "../types/form";

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0;
  margin: 0;
`;

const StyledFormMessage = styled.div<{ $error: boolean }>`
  color: ${({ theme, $error }) => theme.colors[$error ? "red" : "green"]};
  margin-top: 5px;
  margin-bottom: 10px;
`;

const TermsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

type DefaultFormProps = {
  endpoint?: string;
};

const DefaultForm: React.FC<DefaultFormProps> = ({
  endpoint = "https://test.pl/mailer",
}) => {
  const [formInfo, setFormInfo] = useState({
    text: "",
    error: false,
  });

  const ref = useRef(null);

  return (
    <Formik
      innerRef={ref}
      initialValues={CONTACT_FORM_INIT_VALUES}
      validationSchema={CONTACT_FORM_SCHEMA}
      onSubmit={(
        values,
        { setSubmitting, resetForm }: FormikHelpers<ContactFormValues>
      ) => {
        if (formInfo.text && !formInfo.error) return;
        (async () => {
          try {
            setSubmitting(true);

            await new Promise((resolve) => setTimeout(resolve, 2000));

            console.log(endpoint);

            setSubmitting(false);
            setFormInfo({
              text: "Dziękujemy za wysłanie formularza!",
              error: false,
            });

            setTimeout(() => {
              setFormInfo({ text: "", error: false });
              resetForm();
            }, 5000);
          } catch (err) {
            setSubmitting(false);
            setFormInfo({ text: "Coś poszło nie tak!", error: true });
            setTimeout(() => {
              setFormInfo({ text: "", error: false });
            }, 5000);
          }
        })();
      }}
    >
      {({ errors, touched, isSubmitting }) => (
        <StyledForm>
          <Field
            name="name"
            label="Imię i nazwisko"
            component={Input}
            required
          />

          <Field name="email" label="Adres e-mail" component={Input} required />

          <Field
            name="phone"
            label="Numer telefonu"
            component={Input}
            required
          />

          <Field
            name="message"
            label="Wiadomość"
            component={Input}
            textarea
            required
          />

          <TermsWrapper>
            <Field
              type="checkbox"
              name="agree1"
              label="Wyrażam zgodę na przetwarzanie moich danych osobowych podanych w formularzu kontaktowym w celu udzielenia odpowiedzi na zadane przeze mnie pytanie zgodnie z przepisami Ogólnego Rozporządzenia o Ochronie Danych Osobowych (RODO) i Polityki prywatności."
              component={Checkbox}
              white
            />
            <Field
              type="checkbox"
              name="agree2"
              label="Wyrażam zgodę na przetwarzanie moich danych osobowych podanych w formularzu kontaktowym w celu udzielenia odpowiedzi na zadane przeze mnie pytanie zgodnie z przepisami Ogólnego Rozporządzenia o Ochronie Danych Osobowych (RODO) i Polityki prywatności."
              component={Checkbox}
              white
            />
            <Field
              type="checkbox"
              name="agree3"
              label="Wyrażam zgodę na przetwarzanie moich danych osobowych podanych w formularzu kontaktowym w celu udzielenia odpowiedzi na zadane przeze mnie pytanie zgodnie z przepisami Ogólnego Rozporządzenia o Ochronie Danych Osobowych (RODO) i Polityki prywatności."
              component={Checkbox}
              white
            />
          </TermsWrapper>

          {formInfo.text && (
            <StyledFormMessage $error={formInfo.error}>
              {formInfo.text}
            </StyledFormMessage>
          )}

          <Button
            disabled={!!(formInfo.text && !formInfo.error)}
            loading={isSubmitting}
            primary
          >
            Wyślij
          </Button>
        </StyledForm>
      )}
    </Formik>
  );
};

export default DefaultForm;
