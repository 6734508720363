import { createRoot } from "react-dom/client";
import { App } from "./App";

const loadGoogleFonts = () => {
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.href =
    "https://fonts.googleapis.com/css2?family=Lexend:wght@400;700&display=swap";
  document.head.appendChild(link);
};

loadGoogleFonts();

const appContainer = document.createElement("div");
appContainer.id = "dynamic-popup-container";
document.body.appendChild(appContainer);

const root = createRoot(appContainer);
root.render(<App />);
