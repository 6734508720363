import { css, createGlobalStyle } from "styled-components";

const Global = css`
  #dynamic-popup-container {
    font-family: Lexend, sans-serif;
    font-size: 16px;

    * {
      box-sizing: border-box;
    }

    p {
      margin: 0;
    }

    img {
      margin: 0;
    }
  }
`;

const GlobalStyles = createGlobalStyle`
    ${Global} 
`;

export default GlobalStyles;
